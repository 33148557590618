import React from 'react';
import Layout from '../organisms/layout';
import Seo from '../organisms/seo';
import FasciaDiErrore from '../molecules/fascia-di-errore/fascia-di-errore';
import { useStaticQuery, graphql } from 'gatsby';
const Page503 = () => {
  const { error503PageData } = useStaticQuery(graphql`
    {
      error503PageData: allLiferayModulePaginaErrore(
        filter: { liferayFields: { title: { eq: "Pagina - Errore - 503" } } }
      ) {
        nodes {
          liferayFields {
            langCode
          }
          button1Text {
            content {
              slug1 {
                value
              }
            }
            value
          }
          errorCode {
            value
          }
          errorDescription {
            value
          }
        }
      }
    }
  `);
  return (
    <Layout
      pageSettings={{
        pageType: 'landing',
      }}
    >
      <Seo
        title='503: Internal Server Error'
        robots='noindex, nofollow'
        link='/page503'
      />
      <div className='container'>
        <FasciaDiErrore data={error503PageData?.nodes[0]} />
      </div>
    </Layout>
  );
};

export default Page503;
