import classNames from 'classnames';
import React from 'react';
import ButtonWithLink from '../../organisms/button/Button';
import './fascia-di-errore.scss';
const FasciaDiErrore = ({ data }) => {
  const button1Text = data?.button1Text?.value || '';
  const button2Text = data?.button2Text?.value || '';
  const errorCode = data?.errorCode?.value;
  const errorDescription = data?.errorDescription?.value;
  const separatorText = data?.separatorText?.value;
  const button1Link = data?.button1Text?.content?.slug1?.value || '';
  const button2Link = data?.button1Text?.content?.slug2?.value || '';

  return (
    <div className='wrapper error__wrapper module-top-gap'>
      <div className='error__outer'>
        <div className='error__inner'>{errorCode}</div>
        <div className='error__pallocchio'></div>
      </div>
      <p className='error__description'>{errorDescription}</p>
      <section
        className={classNames('error__buttons', {
          'error__buttons--grid': button2Text,
        })}
      >
        {button1Text && (
          <ButtonWithLink
            text={button1Text}
            type={'primary'}
            href={`/${button1Link}`}
          />
        )}
        {button1Text && button2Text && (
          <>
            <span className='error__separator'>{separatorText || ''}</span>

            <ButtonWithLink
              type={'primary'}
              text={button2Text}
              href={`/${button2Link}`}
            />
          </>
        )}
      </section>
    </div>
  );
};

export default FasciaDiErrore;
